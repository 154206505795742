/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Date:     20/11//2018
	Author:   Edge Marketing Solutions
	Version:  5.1

=============================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

//===========================================================================//
//                        [Font Settings]                                    //
//===========================================================================//
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');
				  $font-decorative: 'Open Sans', Arial, sans-serif;
					 $font-default: 'Open Sans', Arial, sans-serif;
							$light: 300;
						  $regular: 400;
							 $bold: 700;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
					$color-primary: #0066CC;
				  $color-secondary: #F58220;
				   $color-tertiary: #AAD152;
				 $color-quaternary: #004891;
					$color-default: rgb(109, 110, 113);

//===========================================================================//
//                        [Slider Settings]                                  //
//===========================================================================//
				   $slider-overlay: white;
			   $slider-overlay-bar: rgba(white, 0.5);
	   $slider-text-shadow-opacity: 0 0 3px rgba(0, 0, 0, 0.25);
					   $gradient-1: transparent;
					   $gradient-2: transparent;

/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
				      $download-bg: $color-secondary;
			$download-bg-secondary: $color-secondary;
				   $download-color: white;
				   $download-title: white;
				  $download-btn-bg: $color-primary;
			   $download-btn-color: white;
			$download-btn-bg-hover: darken($color-secondary, 25%);
		 $download-btn-color-hover: white;

/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
			            $footer-bg: darken($color-tertiary, 5%);
					 $footer-color: rgba(white, 0.66);
				   $footer-a-color: $footer-color;
				   $footer-a-hover: rgba(white, 1);
					   $row-social: $footer-color;
				 $row-social-hover: $footer-a-hover;
				 		$footer-h5: $color-quaternary;

/*=========================================================================*/

/* [Global Template Styles] */

body
{
	color: $color-default;
	font-family: $font-default;
	font-weight: $regular;
	font-size: 16px;
}

h1
{
	margin: 0 0 20px 0;

	color: $color-primary;
	font-size: 40px;
	font-weight: $regular;
}

h2
{
	margin: 20px 0;

	color: $color-primary;
	font-size: 30px;
	font-weight: $regular;;
}

h3
{
	margin: 20px 0;

	color: $color-secondary;
	font-size: 22px;
	font-weight: $regular;
}

h4
{
	color: $color-default;
	font-size: 18px;
	font-weight: $regular;
}

h5
{
	font-weight: $bold;
	text-transform: uppercase;
	font-size: 13px;
}

p
{
	margin-bottom: 15px;
	line-height: 1.6em;
}

p.lead
{
	color: $color-secondary;
	font-size: 24px;
	font-weight: $regular;
}

.row-spaced
{
	margin-bottom: 30px;
}

a
{
	transition: all 150ms ease-in-out;

	color: $color-primary;
}

	a:hover,
	a:focus,
	a:active {
		text-decoration: none;
		color: $color-secondary;
	}

img
{
	max-width: 100%;
}

img.svg-responsive
{
	width: 100% \9;
}

/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
.navbar-nav.nav-justified > li {
	float: none!important;
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	h1
	{
		font-size: 26px;
	}
}

/* Landscape phones and down */
@media (max-width: 480px) {

}

			  $header-bg-color: $color-primary;
		 $banner-overlay-color: rgba(255, 255, 255, 0.3);
  $feature-image-overlay-color: rgba($color-secondary, 0.3);
				   $gradient-1: $color-tertiary;
				   $gradient-2: $color-primary;
			   $slider-overlay: $color-primary;

//=========================================================================//
// [Header Template Styles] //
//=========================================================================//
.header
{
	width: 100%;
	padding: 65px 0;
	position: relative;
	z-index: 0;

	&:before
	{
		content: ' ';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;

		background: linear-gradient(90deg, rgba($gradient-1, 1) 0%, rgba($gradient-2, 1) 100%);
		opacity: 0.5;
	}

	.h-logo
	{
		img
		{
			width: 80%;
		}
	}

	.container
	{
		position: relative;
		z-index: 100;
	}
}

.header-homepage
{
	padding: 0;
	position: relative;
	z-index: 0;

	.container-header
	{
		position: absolute !important;
		top: 65px;
		left: 50%;
		transform: translateX(-50%);
	}
}


.header-subpage
{
	padding: 65px 0;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center!important;

	&:before
	{
		content: ' ';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;

		background: linear-gradient(90deg, rgba($gradient-1, 1) 0%, rgba($gradient-2, 1) 100%);
		opacity: 0.5;
	}
}


/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.header .h-logo
	{
		text-align: center;

		img
		{
			width: 40%;
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 860px) {
	.header
	{
		padding: 35px 0 0 0;
		background: black;

		.h-logo {
			text-align: center;
			img
			{
				width: 50%;
			}
		}
	}

	.header-subpage
	{
		padding: 35px 0;
	}

	.header-homepage .container-header
	{
		position: relative !important;
		top: 0;
		padding-bottom: 20px;
	}
}

//===========================================================================//
//                    [Navigation Settings]                                  //
//===========================================================================//
						// Navbar Desktop
					  $nav-d-color: lighten($color-secondary, 40);
				$nav-d-color-hover: white;

						// Navbar Desktop Dropdown
	      $nav-d-dropdown-bg-color: white;
	$nav-d-dropdown-bg-color-hover: $color-primary;
	      	 $nav-d-dropdown-color: $color-secondary;
	   $nav-d-dropdown-color-hover: $color-primary;
	  $nav-d-dropdown-color-active: $color-primary;

						// Navbar Mobile
					  $nav-m-color: white;
			   $nav-m-color-active: white;
			$nav-m-bg-color-active: transparentize($color-primary, 0.5);
		  	   $nav-m-border-color: #d4d4d4;

					$nav-bg-toggle: $color-quaternary;
	   		 $nav-bg-toggle-active: $color-primary;
	   $nav-bg-toggle-active-color: $color-primary;
			  $nav-bg-toggle-color: white;

			 $nav-m-dropdown-color: $nav-m-color;
	  $nav-m-dropdown-color-active: $color-primary;
				$nav-m-dropdown-bg: rgba(white, 0.5);
			 $nav-m-dropdown-li-bg: $nav-m-dropdown-bg;
	  $nav-m-dropdown-li-bg-active: $nav-m-bg-color-active;
		$nav-m-dropdown-box-shadow: inset 0 0 10px 2px #C7C7C7;
				  $nav-m-li-border: 1px solid rgba(white, 0.5);

						$logo-text: $color-secondary;
				  $logo-text-hover: $color-default;
				 $logo-text-mobile: $color-primary;
		   $logo-text-hover-mobile: $color-default;

			  $centre-select-color: $color-primary;
		$centre-select-color-hover: white;
		   $centre-select-bg-color: transparent;
	 $centre-select-bg-color-hover: $color-primary;
			 $centre-select-border: solid 1px transparentize($color-primary, 0.75);
	   $centre-select-border-hover: solid 1px transparentize($color-primary, 0.75);


//=========================================================================//
// [Navigation Template Styles] //
//=========================================================================//
.navbar-default
{
	min-height: 10px;
	float: right;
	margin: 30px 0 0 0;
	width: 100%;

	background-image: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;

	font-weight: 400;

	.navbar-toggle
	{
		width: 100%;
		margin: 0;
		padding: 15px;

		background-color: $nav-bg-toggle;
		border: none;

		font-size: 16px;
		color: white;

		&:hover,
		&:focus
		{
			background-color: $nav-bg-toggle-active;
		}
	}

	.navbar-collapse
	{
		padding-left: 0;
		padding-right: 0;
	}

	.navbar-nav
	{
		> li
		{
			> a
			{
				padding: 5px 10px;

				font-size: 16px;
				font-weight: 400;
				color: $nav-d-color;
				text-shadow: none;

				&:hover
				{
					color: $nav-d-color-hover;
				}
			}
		}

		> li:first-child a
		{
			padding-left: 0;
		}

		> li:last-child a
		{
			padding-right: 0;
		}

		> .open
		{
			> a,
			> a:hover,
			> a:focus
			{
				background-color: $nav-d-dropdown-bg-color-hover;
				color: $nav-d-dropdown-color-hover;
			}
		}

		> .active
		{
			> a,
			> a:hover,
			> a:active,
			> a:focus
			{
				background-color: transparent;

				color: $nav-d-color-hover;
			}
		}

		> .dropdown-menu
		{
			width: 100%;
		}

		.dropdown-menu
		{
			> li
			{
				> a
				{
					background: $nav-d-dropdown-bg-color;

					&:hover,
					&:focus
					{
						background: transparent;
						color: $color-primary;
					}
				}
			}

			> .active > a,
			> .active > a:hover,
			> .active > a:focus
			{
				background: transparent;
				color: $color-primary;
			}
		}

		> li > a.selector-centre
		{
			display: block;
			box-sizing: border-box;
			position: relative;

			background-color: $centre-select-bg-color;
			color: $centre-select-color;
			font-weight: 400;
			font-size: 18px;
			line-height: 1.0em;
			text-align: center;

			&:hover
			{
				background-color: $centre-select-bg-color-hover;
				color: $centre-select-color-hover;

				text-decoration: none;
				cursor: pointer;
			}

			small
			{
				font-size: 14px;
			}
		}
	}

	.navbar-collapse,
	.navbar-form
	{
		border: none;
	}
}

/* Large desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-default
	{
		margin: 48px 0 0 0;

		.navbar-nav > li > a
		{
			padding: 5px 13px;

			font-size: 13px;

			&.selector-centre
			{
				margin-right: 7px;
				padding: 8px 20px;
				border-radius: 5px;

				border: $centre-select-border;
			}
		}
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) {
	.navbar-nav {
		float: right;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.navbar-default
	{
		margin-top: 20px;
		float: none;

		text-align: center;

		.navbar-nav
		{
			display: inline-block;
			float: none;

			> li > a
			{
				padding: 5px 10px;

				font-size: 14px;

				&.selector-centre
				{
					padding: 5px 8px;
					border-radius: 5px;

					border: solid 1px transparentize($color-primary, 0.75);
				}
			}
		}
	}
}

/* Hover Click Fix */
@media (min-width: 768px) {
	.dropdown:hover .dropdown-menu {
		display: block;
		margin-top: 0;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.navbar-default
	{
		margin-top: 20px;
		float: none;

		.navbar-nav
		{
			margin: 0;
			background-color: $nav-m-dropdown-bg;

			> li
			{
				border-bottom: $nav-m-li-border;

				&:active,
				&:focus,
				&.active
				{
					a {
						background-color: $nav-m-bg-color-active;
						color: $nav-m-color-active;
					}
				}

				> a
				{
					padding: 15px 0;

					font-size: 16px;
					color: $nav-m-color;

					text-align: center;

					&:hover,
					&:focus,
					&:active
					{
						background-color: $nav-m-dropdown-li-bg-active;
						color: $nav-m-color-active;
					}
				}
			}

			.open
			{

				.dropdown-menu
				{
					border-radius: 0 6px 6px 6px;
					box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
					padding: 0;

					> li {

						> a {
							background: $nav-m-dropdown-li-bg;
							color:  $nav-m-dropdown-color;
							padding: 15px 0;

							font-size: 16px;

							text-align: center;

							&:hover,
							&:focus,
							&:active
							{
								background-color: $nav-m-dropdown-li-bg-active;
								color: $nav-m-color-active;
							}
						}
					}
				}
			}
		}
	}
}

//=========================================================================//
// [Slick Slider Styles] //
//=========================================================================//
.slider-overlay
{
	position: absolute;
	z-index: 100;
	width: 100%;
	top: 50%;
	transform: translateY(-100%);

	color: $slider-overlay;
	font-size: 50px;
	text-align: center;
	font-weight: $regular;

	small
	{
		color: $color-secondary;
		font-weight: 300;
	}
}

.slider-parallax
{
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 90vh;
	top: 0;

	.slider-wrapper
	{
		width: 100%;
		height: 100%;
		position: relative;
		top: 0;
		z-index: 0;

		.slickSlider {
			img
			{
				display: none;
			}

			&.slick-initialized img
			{
				display: block;
				width: 100%;
			}

			.slick-prev
			{
				left: 10px;
				z-index: 100;
				opacity: 0;
			}

			.slick-next
			{
				right: 10px;
				z-index: 100;
				opacity: 0;
			}

			&:hover
			{
				.slick-prev,
				.slick-next
				{
					opacity: 0.75;
				}
			}
		}
	}
}

/* HD */
@media (min-width: 1200px) and (max-width: 1919px) {
	.slider-parallax
	{
		height: 80vh;

		.slider-wrapper
		{
			width: 100%;
			height: 200%;
			position: relative;
			top: 50%;
			z-index: 0;
		}
	}
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-parallax
	{
		height: 70vh;

		.slider-wrapper
		{
			width: 100%;
			height: 200%;
			position: relative;
			top: 50%;
			z-index: 0;
		}
	}
	.slider-overlay
	{
		font-size: 40px;
	}
}

/* Desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.slider-parallax
	{
		height: 60vh;

		.slider-wrapper
		{
			width: 100%;
			height: 150%;
			position: relative;
			top: 20%;
			z-index: 0;
		}
	}
}

@media (max-width: 860px)
{
	.slider-parallax
	{
		height: 86vh;
		position: absolute;
		top: 0;

		.slider-wrapper
		{

		}
	}
}

@media (max-width: 580px)
{
	.slider-parallax
	{
		height: 60vh;

		.slider-wrapper
		{
			width: 100%;
			height: 200%;
			position: relative;
			top: 40%;
			z-index: 0;
		}
	}
}

@media (max-width: 580px) and (max-height: 775px)
{
	.slider-parallax {

		height: 80vh;

		.slider-wrapper
		{
			width: 100%;
			height: 200%;
			position: relative;
			top: 40%;
			z-index: 0;
		}
	}
}

@media (max-width: 380px)
{
	.slider-parallax
	{

		.slider-wrapper
		{
			top: 50%;
		}
	}
}

/* iPad */
@media (width: 768px) and (height: 1024px)
{
	.slider-parallax
	{
		height: 48vh;
	}
}

/* iPad Pro */
@media (width: 1024px) and (height: 1366px)
{
	.slider-parallax
	{
		height: 56vh;
	}
}

/* iPhone 6/7/8 */
@media (width: 414px) and (height: 736px)
{
	.slider-parallax
	{
		height: 57vh;
		top: -175px;
	}
}

/* iPhone 5/SE */
@media (width: 568px) and (height: 320px)
{
	.slider-parallax
	{
		height: 90vh;
		top: 0;

		.slider-wrapper
		{
			top: 0;
		}
	}
}

/* Pixel 2 */
@media (width: 411px) and (height: 731px)
{
	.slider-parallax
	{
		height: 40vh;

		.slider-wrapper
		{
			top: 40%;
		}
	}
}

//===========================================================================//
//                  [Feature Navigation Settings]                            //
//===========================================================================//
                   $feature-nav-bg: white;
                $feature-nav-color: white;
                $feature-nav-title: white;
          $feature-nav-title-hover: white;
         $feature-nav-btn-bg-color: $color-secondary;
            $feature-nav-btn-color: white;
         $feature-nav-btn-bg-hover: $color-primary;
            $feature-nav-btn-hover: $color-secondary;
      $feature-nav-btn-hover-color: $color-secondary;
     $feature-nav-btn-border-color: $color-secondary;
$feature-nav-btn-border-color-hover: $color-secondary;

//=========================================================================//
// [Feature Navigation Template Styles] //
//=========================================================================//
.feature-navigation
{
	width: 100%;
	z-index: 1;
	position: absolute;
	bottom: 60px;

	.feature-button
	{
		transition: all 200ms ease-in-out;
		border-radius: 10px;

		&:hover
		{
			background: $feature-nav-btn-bg-hover;
			color: white!important;
			box-shadow: inset 0 0 10px 2px rgba(black, 0.15);

			.item span
			{
				color: white;
			}
		}

		.item
		{
			display: block;
			width: 100%;
			padding: 20px;
			position: relative;

			text-align: center;
			color: $feature-nav-color;
			font-size: 30px;
			line-height: 1.0em;

			&:hover
			{
				text-decoration: none;
				color: white!important;
			}

			span
			{
				width: 136px;
				margin-bottom: 20px;
				color: $feature-nav-color;
				font-size: 60px;
				transition: all 200ms ease-in-out;
			}

			small
			{
				font-size: 18px;
			}
		}
	}
}

// Large desktop //
@media (min-width: 1199px) {
	.feature-navigation .item h2 {
		font-size: 28px;
	}
}

@media (min-width: 768px) and (max-width: 860px)
{
	.feature-navigation {
		bottom: 0!important;
		background: $color-quaternary;
		padding: 20px 0;

		.feature-button
		{
			.item
			{
				color: white;

				span
				{
					color: white;
				}
			}
		}
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.feature-navigation
	{
		.feature-button
		{
			.item {
				font-size: 22px;

				h2 {
					font-size: 14px;
					font-weight: $regular;
				}
			}
		}
	}

}

// Landscape phone to portrait tablet //
@media(min-width: 767px) and (max-width: 860px) {
	.feature-navigation {
		width: 100%;
		z-index: 1;
		position: relative;
		bottom: 0;
		background: $color-quaternary;

		.feature-button
		{
			.item {
				width: 100%;
				float: left;
				padding: 20px 0;
				box-sizing: border-box;
				border-left: none;
				text-align: center;
				color: white;
				font-size: 16px;

				h2 {
					font-size: 22px;
				}

				&:last-child {
					border-right: none;
				}
			}
		}
	}
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.feature-navigation {
		width: 100%;
		z-index: 1;
		position: relative;
		bottom: 0;
		background: $color-quaternary;

		.feature-button
		{
			padding: 0;

			.item {
				width: 100%;
				float: left;
				padding: 20px 0;
				box-sizing: border-box;
				border-left: none;
				text-align: center;
				color: white;
				font-weight: $light;
				font-size: 18px;
				border-bottom: solid 1px transparentize(white,0.75);

				&.item-style-1
				{
					border-top: solid 1px transparentize(white,0.75);
				}

				span
				{
					width: auto;
					font-size: 16px;
					margin: 0 5px 0 0;
					color: white;
				}

				h2 {
					font-size: 22px;
				}

				&:last-child {
					border-right: none;
				}
			}
		}
	}
}

//=========================================================================//
// [Page Template Styles] //
//=========================================================================//
.page {
	padding: 40px 0 0 0;
	min-height: 60vh;
	background: white;

	&.homepage
	{
		text-align: center;
		min-height: auto;
	}

	h3.location-title
	{
		margin-top: 0;
	}
}

//===========================================================================//
//                  [Book Button Settings]                                   //
//===========================================================================//
				   $book-btn-color: white;
				   $book-btn-hover: white;
					  $book-btn-bg: $color-quaternary;
				$book-btn-bg-hover: darken($book-btn-bg, 10%);
				  $book-btn-border: darken($book-btn-bg, 20%);

//=========================================================================//
// [Buttons Template Styles] //
//=========================================================================//
.btn
{
	transition: all 200ms ease-in-out;
}
.btn-primary {
	background-color: $color-primary !important;
	border-color: $color-primary !important;
	color: white !important;

	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-primary, 10) !important;
		border-color: darken($color-primary, 10) !important;
		color: white;
	}

	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}

	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5) !important;
	}
}
.btn-book {
	background-color:  $color-tertiary !important;
	border-color:  $color-tertiary !important;
	color: white !important;

	&:not(:disabled):not(.disabled):active {
		background-color: darken( $color-tertiary, 10) !important;
		border-color: darken( $color-tertiary, 10) !important;
		color: white;
	}

	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}

	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-tertiary, 0.5) !important;
	}
}

.btn-success {
	background-color: $color-primary;
	border-color: darken($color-primary, 15%);

	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.container-book {
	position: relative;

	.book-btn-header {
		position: absolute;
		z-index: 1000;
		top: -20px;
		right: 0;

		a {
			border: solid 1px $book-btn-border;
			color: $book-btn-color;
			background: $book-btn-bg;
			padding: 8px 30px;
			font-size: 14px;
			border-radius: 5px;

			&:hover,
			&:active,
			&:focus {
				border: solid 1px $book-btn-border;
				background: $book-btn-bg-hover;
				color: $book-btn-hover
			}
		}
	}
}

@media(max-width: 767px) {
	.container-book {
		.book-btn-header {
			position: relative;
			width: 100%;
			margin-top: 12px;
			top: 0;

			a {
				display: block;
			}
		}
	}
}

.listing
{
    padding-bottom: 1rem;
    height: 100%;
    display: flex;
    flex-flow: column;

    background-color: white;

    a:hover
    {
        text-decoration: none;
    }

    .listing-details
    {
        padding: 1rem;
        flex-basis: 100%;

        h4
        {
            margin: 0 0 1rem 0;

            font-size: 1.2rem;
        }
    }

    .listing-action
    {
        position: relative;
        margin-left: 1rem;
        padding: 0.5rem 1rem;

        background-color: $color-primary;

        color: white;
    }

    .listing-action:before
    {
        position: absolute;
        content: ' ';
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;

        background-color: $color-secondary;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30%;
    }

    .listing-action:hover
    {
        background-color: $color-secondary;
    }

}

//=========================================================================//
// [Download Template Styles] //
//=========================================================================//
.download {
	width: 100%;
	margin: 0 0 20px 0;
	float: left;

	.icon {
		width: 100%;
		float: left;
		height: 120px;
		background-image: url('../images/ebook-icon.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.details {
		width: 100%;
		padding: 15px;
		height: 100%;
		float: left;
		background-color: $download-bg;
		color: $download-color;
		text-align: center;

		h3 {
			margin: 0 0 5px;
			padding: 0;
			color: $download-title;
			line-height: 1.0em;
			font-size: 16px;
			font-weight: bold;
		}

        a {
            &.btn {
                margin: 8px 0 0 0;
                background-color: $download-btn-bg;
                color: $download-btn-color;

                &:hover {
                    background-color: $download-btn-bg-hover;
                    color: $download-btn-color-hover;
                }
            }
        }

		&.secondary {
			background-color: $download-bg-secondary!important;

			a {
				&.btn {

					&:hover {
						background-color: darken($download-bg-secondary, 20%);
						color: $download-btn-color-hover;
					}
				}
			}
		}
	}
}

// Large desktop //
@media (min-width: 1200px) {
	.download .details h3 {
		font-size: 20px;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.download {
		height: auto;

		.icon {
			width: 100%;
			height: 100px;
		}

		.details {
			width: 100%;
			height: auto;
			text-align: center;

			h3 {
				font-size: 17px;
				font-weight: bold;
			}
		}
	}
}


//=========================================================================//
// [Footer Template Styles] //
//=========================================================================//
.footer {
	width: 100%;
	padding: 40px 0;
	margin: 40px 0 0 0;
	background-color: $footer-bg;
	color: $footer-color;
	font-size: 14px;

	h5 {
		margin-top: 0;
		color: $footer-h5
	}

	a {
		color: $footer-a-color;

		&:hover {
			color: $footer-a-hover;
			text-decoration: none;
		}

		.btn {
			margin: 0 0 30px 0;
			background-color: rgba(0, 0, 0, 0.4);
			color: white;

			&:hover {
				background-color: white;
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		> li {
			padding: 5px 0;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}

	.row-social {
		margin-bottom: 10px;

		font-size: 30px;
		text-align: right;

		a {
			color: $row-social;

			&:hover {
				color: $row-social-hover;
			}
		}
	}
}


.logo-chiropractic-iq,
.logo-caa {
	transition: all 150ms ease-in-out;
	width: 100%;
	opacity: 0.5;
	float: right;

	&:hover {
		opacity: 1;
	}
}

.logo-caa {
	width: 100%;
	margin-bottom: 10px;
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.footer {
		text-align: center;

		h5 {
			margin: 30px 0 10px 0;
		}

		.row-social {
			font-size: 40px;
			margin: 0;
			text-align: center;

			a.instagram
			{
				font-size: 4.9rem;
				margin-left: 20px;
			}
		}
	}
	.logo-chiropractic-iq {
		margin: 20px auto;
		width: 50%;
		float: none;
	}
	.logo-caa {
		width: 50%;
		margin: 40px auto 0 auto;
		float: none;
	}
}

//=========================================================================//
// [Bootstrap Row Clear Fix Styles] //
#media-query-detector {
	display: none;
	width: 0;
}

@media (min-width: 768px) {
	#media-query-detector {
		width: 768px;
	}
}

@media (min-width: 992px) {
	#media-query-detector {
		width: 992px;
	}
}

@media (min-width: 1200px) {
	#media-query-detector {
		width: 1200px;
	}
}

.row-fix-clear-both {
	clear: both;
}

//=========================================================================//
// [Table as row override Styles] //
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
	content: " ";
	display: table;
	clear: both;
}

table.table-as-row > tbody > tr {
	height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
	display: block;
	width: auto;
}

table.table-as-row > tbody > tr {
	display: block;
	width: auto;
	margin-right: -15px;
	margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
	display: block;
	height: auto !important;
	margin-bottom: 20px;
}

// col-lg //
@media (min-width: 1200px) {
	table.table-as-row > tbody > tr > td[class*=col-lg-] {
		float: left;
	}
}

// col-md //
@media (min-width: 992px) {
	table.table-as-row > tbody > tr > td[class*=col-md-] {
		float: left;
	}
}

// col-sm //
@media (min-width: 768px) {
	table.table-as-row > tbody > tr > td[class*=col-sm-] {
		float: left;
	}
}

// col-xs //
table.table-as-row > tbody > tr > td[class*=col-xs-] {
	float: left;
}

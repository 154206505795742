/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Date:     20/11//2018
	Author:   Edge Marketing Solutions
	Version:  5.1

=============================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,700");
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #6d6e71;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

h1 {
  margin: 0 0 20px 0;
  color: #0066CC;
  font-size: 40px;
  font-weight: 400;
}

h2 {
  margin: 20px 0;
  color: #0066CC;
  font-size: 30px;
  font-weight: 400;
}

h3 {
  margin: 20px 0;
  color: #F58220;
  font-size: 22px;
  font-weight: 400;
}

h4 {
  color: #6d6e71;
  font-size: 18px;
  font-weight: 400;
}

h5 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
}

p {
  margin-bottom: 15px;
  line-height: 1.6em;
}

p.lead {
  color: #F58220;
  font-size: 24px;
  font-weight: 400;
}

.row-spaced {
  margin-bottom: 30px;
}

a {
  transition: all 150ms ease-in-out;
  color: #0066CC;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #F58220;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
.navbar-nav.nav-justified > li {
  float: none !important;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 26px;
  }
}

/* Landscape phones and down */
.header {
  width: 100%;
  padding: 65px 0;
  position: relative;
  z-index: 0;
}

.header:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(90deg, #aad152 0%, #0066cc 100%);
  opacity: 0.5;
}

.header .h-logo img {
  width: 80%;
}

.header .container {
  position: relative;
  z-index: 100;
}

.header-homepage {
  padding: 0;
  position: relative;
  z-index: 0;
}

.header-homepage .container-header {
  position: absolute !important;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
}

.header-subpage {
  padding: 65px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center !important;
}

.header-subpage:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(90deg, #aad152 0%, #0066cc 100%);
  opacity: 0.5;
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header .h-logo {
    text-align: center;
  }
  .header .h-logo img {
    width: 40%;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 860px) {
  .header {
    padding: 35px 0 0 0;
    background: black;
  }
  .header .h-logo {
    text-align: center;
  }
  .header .h-logo img {
    width: 50%;
  }
  .header-subpage {
    padding: 35px 0;
  }
  .header-homepage .container-header {
    position: relative !important;
    top: 0;
    padding-bottom: 20px;
  }
}

.navbar-default {
  min-height: 10px;
  float: right;
  margin: 30px 0 0 0;
  width: 100%;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-weight: 400;
}

.navbar-default .navbar-toggle {
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #004891;
  border: none;
  font-size: 16px;
  color: white;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #0066CC;
}

.navbar-default .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.navbar-default .navbar-nav > li > a {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  color: #feefe3;
  text-shadow: none;
}

.navbar-default .navbar-nav > li > a:hover {
  color: white;
}

.navbar-default .navbar-nav > li:first-child a {
  padding-left: 0;
}

.navbar-default .navbar-nav > li:last-child a {
  padding-right: 0;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #0066CC;
  color: #0066CC;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:active,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
  color: white;
}

.navbar-default .navbar-nav > .dropdown-menu {
  width: 100%;
}

.navbar-default .navbar-nav .dropdown-menu > li > a {
  background: white;
}

.navbar-default .navbar-nav .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .dropdown-menu > li > a:focus {
  background: transparent;
  color: #0066CC;
}

.navbar-default .navbar-nav .dropdown-menu > .active > a,
.navbar-default .navbar-nav .dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav .dropdown-menu > .active > a:focus {
  background: transparent;
  color: #0066CC;
}

.navbar-default .navbar-nav > li > a.selector-centre {
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: transparent;
  color: #0066CC;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.0em;
  text-align: center;
}

.navbar-default .navbar-nav > li > a.selector-centre:hover {
  background-color: #0066CC;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.navbar-default .navbar-nav > li > a.selector-centre small {
  font-size: 14px;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: none;
}

/* Large desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-default {
    margin: 48px 0 0 0;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 5px 13px;
    font-size: 13px;
  }
  .navbar-default .navbar-nav > li > a.selector-centre {
    margin-right: 7px;
    padding: 8px 20px;
    border-radius: 5px;
    border: solid 1px rgba(0, 102, 204, 0.25);
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) {
  .navbar-nav {
    float: right;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-default {
    margin-top: 20px;
    float: none;
    text-align: center;
  }
  .navbar-default .navbar-nav {
    display: inline-block;
    float: none;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 5px 10px;
    font-size: 14px;
  }
  .navbar-default .navbar-nav > li > a.selector-centre {
    padding: 5px 8px;
    border-radius: 5px;
    border: solid 1px rgba(0, 102, 204, 0.25);
  }
}

/* Hover Click Fix */
@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-default {
    margin-top: 20px;
    float: none;
  }
  .navbar-default .navbar-nav {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .navbar-default .navbar-nav > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .navbar-default .navbar-nav > li:active a, .navbar-default .navbar-nav > li:focus a, .navbar-default .navbar-nav > li.active a {
    background-color: rgba(0, 102, 204, 0.5);
    color: white;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 15px 0;
    font-size: 16px;
    color: white;
    text-align: center;
  }
  .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:active {
    background-color: rgba(0, 102, 204, 0.5);
    color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu {
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    background: rgba(255, 255, 255, 0.5);
    color: white;
    padding: 15px 0;
    font-size: 16px;
    text-align: center;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > li > a:active {
    background-color: rgba(0, 102, 204, 0.5);
    color: white;
  }
}

.slider-overlay {
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 50%;
  transform: translateY(-100%);
  color: #0066CC;
  font-size: 50px;
  text-align: center;
  font-weight: 400;
}

.slider-overlay small {
  color: #F58220;
  font-weight: 300;
}

.slider-parallax {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 90vh;
  top: 0;
}

.slider-parallax .slider-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  z-index: 0;
}

.slider-parallax .slider-wrapper .slickSlider img {
  display: none;
}

.slider-parallax .slider-wrapper .slickSlider.slick-initialized img {
  display: block;
  width: 100%;
}

.slider-parallax .slider-wrapper .slickSlider .slick-prev {
  left: 10px;
  z-index: 100;
  opacity: 0;
}

.slider-parallax .slider-wrapper .slickSlider .slick-next {
  right: 10px;
  z-index: 100;
  opacity: 0;
}

.slider-parallax .slider-wrapper .slickSlider:hover .slick-prev,
.slider-parallax .slider-wrapper .slickSlider:hover .slick-next {
  opacity: 0.75;
}

/* HD */
@media (min-width: 1200px) and (max-width: 1919px) {
  .slider-parallax {
    height: 80vh;
  }
  .slider-parallax .slider-wrapper {
    width: 100%;
    height: 200%;
    position: relative;
    top: 50%;
    z-index: 0;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .slider-parallax {
    height: 70vh;
  }
  .slider-parallax .slider-wrapper {
    width: 100%;
    height: 200%;
    position: relative;
    top: 50%;
    z-index: 0;
  }
  .slider-overlay {
    font-size: 40px;
  }
}

/* Desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .slider-parallax {
    height: 60vh;
  }
  .slider-parallax .slider-wrapper {
    width: 100%;
    height: 150%;
    position: relative;
    top: 20%;
    z-index: 0;
  }
}

@media (max-width: 860px) {
  .slider-parallax {
    height: 86vh;
    position: absolute;
    top: 0;
  }
}

@media (max-width: 580px) {
  .slider-parallax {
    height: 60vh;
  }
  .slider-parallax .slider-wrapper {
    width: 100%;
    height: 200%;
    position: relative;
    top: 40%;
    z-index: 0;
  }
}

@media (max-width: 580px) and (max-height: 775px) {
  .slider-parallax {
    height: 80vh;
  }
  .slider-parallax .slider-wrapper {
    width: 100%;
    height: 200%;
    position: relative;
    top: 40%;
    z-index: 0;
  }
}

@media (max-width: 380px) {
  .slider-parallax .slider-wrapper {
    top: 50%;
  }
}

/* iPad */
@media (width: 768px) and (height: 1024px) {
  .slider-parallax {
    height: 48vh;
  }
}

/* iPad Pro */
@media (width: 1024px) and (height: 1366px) {
  .slider-parallax {
    height: 56vh;
  }
}

/* iPhone 6/7/8 */
@media (width: 414px) and (height: 736px) {
  .slider-parallax {
    height: 57vh;
    top: -175px;
  }
}

/* iPhone 5/SE */
@media (width: 568px) and (height: 320px) {
  .slider-parallax {
    height: 90vh;
    top: 0;
  }
  .slider-parallax .slider-wrapper {
    top: 0;
  }
}

/* Pixel 2 */
@media (width: 411px) and (height: 731px) {
  .slider-parallax {
    height: 40vh;
  }
  .slider-parallax .slider-wrapper {
    top: 40%;
  }
}

.feature-navigation {
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 60px;
}

.feature-navigation .feature-button {
  transition: all 200ms ease-in-out;
  border-radius: 10px;
}

.feature-navigation .feature-button:hover {
  background: #0066CC;
  color: white !important;
  box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.15);
}

.feature-navigation .feature-button:hover .item span {
  color: white;
}

.feature-navigation .feature-button .item {
  display: block;
  width: 100%;
  padding: 20px;
  position: relative;
  text-align: center;
  color: white;
  font-size: 30px;
  line-height: 1.0em;
}

.feature-navigation .feature-button .item:hover {
  text-decoration: none;
  color: white !important;
}

.feature-navigation .feature-button .item span {
  width: 136px;
  margin-bottom: 20px;
  color: white;
  font-size: 60px;
  transition: all 200ms ease-in-out;
}

.feature-navigation .feature-button .item small {
  font-size: 18px;
}

@media (min-width: 1199px) {
  .feature-navigation .item h2 {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  .feature-navigation {
    bottom: 0 !important;
    background: #004891;
    padding: 20px 0;
  }
  .feature-navigation .feature-button .item {
    color: white;
  }
  .feature-navigation .feature-button .item span {
    color: white;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .feature-navigation .feature-button .item {
    font-size: 22px;
  }
  .feature-navigation .feature-button .item h2 {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width: 767px) and (max-width: 860px) {
  .feature-navigation {
    width: 100%;
    z-index: 1;
    position: relative;
    bottom: 0;
    background: #004891;
  }
  .feature-navigation .feature-button .item {
    width: 100%;
    float: left;
    padding: 20px 0;
    box-sizing: border-box;
    border-left: none;
    text-align: center;
    color: white;
    font-size: 16px;
  }
  .feature-navigation .feature-button .item h2 {
    font-size: 22px;
  }
  .feature-navigation .feature-button .item:last-child {
    border-right: none;
  }
}

@media (max-width: 767px) {
  .feature-navigation {
    width: 100%;
    z-index: 1;
    position: relative;
    bottom: 0;
    background: #004891;
  }
  .feature-navigation .feature-button {
    padding: 0;
  }
  .feature-navigation .feature-button .item {
    width: 100%;
    float: left;
    padding: 20px 0;
    box-sizing: border-box;
    border-left: none;
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 18px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.25);
  }
  .feature-navigation .feature-button .item.item-style-1 {
    border-top: solid 1px rgba(255, 255, 255, 0.25);
  }
  .feature-navigation .feature-button .item span {
    width: auto;
    font-size: 16px;
    margin: 0 5px 0 0;
    color: white;
  }
  .feature-navigation .feature-button .item h2 {
    font-size: 22px;
  }
  .feature-navigation .feature-button .item:last-child {
    border-right: none;
  }
}

.page {
  padding: 40px 0 0 0;
  min-height: 60vh;
  background: white;
}

.page.homepage {
  text-align: center;
  min-height: auto;
}

.page h3.location-title {
  margin-top: 0;
}

.btn {
  transition: all 200ms ease-in-out;
}

.btn-primary {
  background-color: #0066CC !important;
  border-color: #0066CC !important;
  color: white !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #004d99 !important;
  border-color: #004d99 !important;
  color: white;
}

.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 204, 0.5) !important;
}

.btn-book {
  background-color: #AAD152 !important;
  border-color: #AAD152 !important;
  color: white !important;
}

.btn-book:not(:disabled):not(.disabled):active {
  background-color: #93be32 !important;
  border-color: #93be32 !important;
  color: white;
}

.btn-book:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-book:focus, .btn-book.focus, .btn-book:active {
  box-shadow: 0 0 0 0.2rem rgba(170, 209, 82, 0.5) !important;
}

.btn-success {
  background-color: #0066CC;
  border-color: #004080;
}

.btn-success:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.container-book {
  position: relative;
}

.container-book .book-btn-header {
  position: absolute;
  z-index: 1000;
  top: -20px;
  right: 0;
}

.container-book .book-btn-header a {
  border: solid 1px #00152b;
  color: white;
  background: #004891;
  padding: 8px 30px;
  font-size: 14px;
  border-radius: 5px;
}

.container-book .book-btn-header a:hover, .container-book .book-btn-header a:active, .container-book .book-btn-header a:focus {
  border: solid 1px #00152b;
  background: #002f5e;
  color: white;
}

@media (max-width: 767px) {
  .container-book .book-btn-header {
    position: relative;
    width: 100%;
    margin-top: 12px;
    top: 0;
  }
  .container-book .book-btn-header a {
    display: block;
  }
}

.listing {
  padding-bottom: 1rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: white;
}

.listing a:hover {
  text-decoration: none;
}

.listing .listing-details {
  padding: 1rem;
  flex-basis: 100%;
}

.listing .listing-details h4 {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
}

.listing .listing-action {
  position: relative;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  background-color: #0066CC;
  color: white;
}

.listing .listing-action:before {
  position: absolute;
  content: ' ';
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  background-color: #F58220;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
}

.listing .listing-action:hover {
  background-color: #F58220;
}

.download {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
}

.download .icon {
  width: 100%;
  float: left;
  height: 120px;
  background-image: url("../images/ebook-icon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.download .details {
  width: 100%;
  padding: 15px;
  height: 100%;
  float: left;
  background-color: #F58220;
  color: white;
  text-align: center;
}

.download .details h3 {
  margin: 0 0 5px;
  padding: 0;
  color: white;
  line-height: 1.0em;
  font-size: 16px;
  font-weight: bold;
}

.download .details a.btn {
  margin: 8px 0 0 0;
  background-color: #0066CC;
  color: white;
}

.download .details a.btn:hover {
  background-color: #8f4506;
  color: white;
}

.download .details.secondary {
  background-color: #F58220 !important;
}

.download .details.secondary a.btn:hover {
  background-color: #a75108;
  color: white;
}

@media (min-width: 1200px) {
  .download .details h3 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .download {
    height: auto;
  }
  .download .icon {
    width: 100%;
    height: 100px;
  }
  .download .details {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .download .details h3 {
    font-size: 17px;
    font-weight: bold;
  }
}

.footer {
  width: 100%;
  padding: 40px 0;
  margin: 40px 0 0 0;
  background-color: #a0cc3e;
  color: rgba(255, 255, 255, 0.66);
  font-size: 14px;
}

.footer h5 {
  margin-top: 0;
  color: #004891;
}

.footer a {
  color: rgba(255, 255, 255, 0.66);
}

.footer a:hover {
  color: white;
  text-decoration: none;
}

.footer a .btn {
  margin: 0 0 30px 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.footer a .btn:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer .row-social {
  margin-bottom: 10px;
  font-size: 30px;
  text-align: right;
}

.footer .row-social a {
  color: rgba(255, 255, 255, 0.66);
}

.footer .row-social a:hover {
  color: white;
}

.logo-chiropractic-iq,
.logo-caa {
  transition: all 150ms ease-in-out;
  width: 100%;
  opacity: 0.5;
  float: right;
}

.logo-chiropractic-iq:hover,
.logo-caa:hover {
  opacity: 1;
}

.logo-caa {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer h5 {
    margin: 30px 0 10px 0;
  }
  .footer .row-social {
    font-size: 40px;
    margin: 0;
    text-align: center;
  }
  .footer .row-social a.instagram {
    font-size: 4.9rem;
    margin-left: 20px;
  }
  .logo-chiropractic-iq {
    margin: 20px auto;
    width: 50%;
    float: none;
  }
  .logo-caa {
    width: 50%;
    margin: 40px auto 0 auto;
    float: none;
  }
}

#media-query-detector {
  display: none;
  width: 0;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
